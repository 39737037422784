const Footer = () => {
    return (
        <div className="Footer">
            <div className="page-container footer-container">
                <div>Ⓒ Studenckie Koła Naukowe Wydziału Zarządzania</div>
                <div>Akademia Górniczo-Hutnicza im. Stanisława Staszica w Krakowie</div>
            </div>
        </div>
    );
}

export default Footer;