import winnerImg from "../../assets/winner.png";
import laureateImg from "../../assets/laureate.png";
import participantImg from "../../assets/participant.png";

export const getPrizes = (club, contests) => {
    return (
        <>
            {contests && contests
                .filter((contest) => contest.status === '-1' || contest.status === '-2')
                .filter((contest, index) => index < 3)
                .map((contest, index) => {
                        let prize;
                        if (contest.winnerClubIds.includes(parseInt(club.clubId))) {
                            prize = winnerImg;
                        } else if (contest.laureateClubIds.includes(parseInt(club.clubId))) {
                            prize = laureateImg;
                        } else if (contest.participantClubIds.includes(parseInt(club.clubId))) {
                            prize = participantImg;
                        }

                        return prize
                            ? (
                                <div className="prize-wrapper" key={index}>
                                    <img className="prize-img" alt="" src={prize}></img>
                                    <span
                                        className="prize-year">{contest.year[0] + contest.year[1]}<br></br>{contest.year[2] + contest.year[3]}</span>
                                </div>
                            )
                            : ''
                    }
                )}
        </>
    )
}