import {Card, Pagination, Spin} from "antd";
import Meta from "antd/es/card/Meta";
import {EditOutlined, EyeOutlined, PlusOutlined} from '@ant-design/icons';
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../context/context";
import Search from "antd/es/input/Search";
import {getClubsList} from "../../api/api";
import InfoModal from "../InfoModal";
import {getPrizes} from "./utils";

const ClubsList = ({mode, winners}) => {

    const navigate = useNavigate();
    const {message, setMessage} = useContext(AppContext);
    const {clubList, setClubList} = useContext(AppContext);
    const {setClubInEdition} = useContext(AppContext);
    const {userClubIds} = useContext(AppContext);
    const [filteredClubs, setFilteredClubs] = useState([]);
    const numEachPage = 8;
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(numEachPage);
    const {contests} = useContext(AppContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        getClubsList().then(response => {
            setClubList(response);
        }).catch((response) => {
            response.json()
                .then((json) => {
                    setMessage(json.msg);
                }).catch((e) => {
                setMessage('Wystąpił błąd');
            })
        })
    }, [mode]);

    useEffect(() => {
        let filteredItems = clubList;
        if (mode === 'users' && userClubIds.length) {
            filteredItems = clubList.filter(c => userClubIds.includes(c.clubId))
        } else if (mode === 'winners') {
            filteredItems = clubList.filter(c => winners.includes(parseInt(c.clubId)))
        }

        setFilteredClubs(filteredItems);
        setLoading(false);
    }, [clubList, winners]);

    const handleOk = () => {
        setMessage('');
    };

    const onSearch = (value) => {
        if (!value.length) {
            setFilteredClubs(clubList);
        } else {
            const filteredClubs = clubList.filter(c =>
                c.clubName.toLowerCase().includes(value.toLowerCase()) ||
                c.clubNameShort.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredClubs(filteredClubs);
        }
        handlePaginationChange(1)
    }
    const showDetails = (e, id) => {
        navigate(`/kolo/?id=${id}`);
    }
    const edit = (e, id) => {
        setClubInEdition(id);
        navigate('/edycja-kola');
    }

    const addAnotherClub = () => {
        navigate(`/rejestracja`);
    }

    const handlePaginationChange = value => {
        setMin((value - 1) * numEachPage)
        setMax(value * numEachPage)
    };

    const getClubs = (clubs, edit, showDetails, width) => {
        const slicedClubs = mode === 'all'
            ? clubs.slice(min, max)
            : clubs;
        return slicedClubs.map(c => {
                const allowToEdit = userClubIds.includes(c.clubId);

                return (
                    <div key={c.clubId} style={{position: 'relative'}}>
                        <div className="prizes-small">
                            {getPrizes(c, contests)}
                        </div>
                        <Card
                            className="clubCard"
                            key={c.clubId}
                            style={{
                                width: width,
                            }}
                            hoverable
                            cover={
                                <div className="img-wrapper"
                                     onClick={(e) => showDetails(e, c.clubId)}
                                >
                                    <img
                                        alt=""
                                        src={c.logo ? c.logo.url : ''}
                                    />
                                </div>
                            }
                            actions={[
                                <EditOutlined
                                    className={allowToEdit ? 'allow-edit' : 'disable-edit'}
                                    style={{fontSize: '20px'}}
                                    key="edit"
                                    onClick={(e) => edit(e, c.clubId)}
                                />,
                                <EyeOutlined
                                    className={'allow-edit'}
                                    style={{fontSize: '20px'}}
                                    key="ellipsis"
                                    onClick={(e) => showDetails(e, c.clubId)}/>,
                            ]}
                        >
                            <Meta
                                style={{minHeight: 80}}
                                title={
                                    <div className="section-title"
                                         onClick={(e) => showDetails(e, c.clubId)}
                                    >
                                        {c.clubName}
                                    </div>
                                }
                                description={<div style={{minHeight: 22}}>{c.clubNameShort}</div>}
                            />
                        </Card>
                    </div>
                )
            }
        )
    }

    return loading
        ? (
            <div className="loading">
                <Spin size="large"/>
            </div>
        )
        : (
        <div className="ClubsList page-container">
            <InfoModal message={message} handleOk={handleOk}></InfoModal>
            {mode === 'all' &&
                <Search
                    placeholder="Wyszukaj koło naukowe"
                    allowClear
                    onSearch={onSearch}
                    style={{
                        width: 360,
                        margin: 'auto',
                        display: 'block'
                    }}
                />}
            <div className="clubs-wrapper">
                {getClubs(filteredClubs, edit, showDetails, mode === 'winners' ? 293 : 300)}
                {mode === 'users' &&
                    <Card
                        onClick={(e) => addAnotherClub(e)}
                        className="clubCard"
                        style={{width: 300}}
                        hoverable
                        cover={
                            <div className="img-wrapper">
                                <PlusOutlined style={{fontSize: 50, color: 'rgba(0, 0, 0, 0.45)'}}/>
                            </div>
                        }
                        actions={[
                            <div style={{height: 24}}></div>
                        ]}
                    >
                        <Meta
                            style={{minHeight: 80, alignItems: 'center', textAlign: 'center', fontSize: 16}}
                            description={<div style={{minHeight: 22}}>Zarejestruj kolejne koło</div>}
                        />
                    </Card>
                }
            </div>
            <div style={{marginBottom: '30px', display: 'flex', justifyContent: 'center'}}>
                <Pagination
                    defaultCurrent={1}
                    defaultPageSize={numEachPage}
                    onChange={handlePaginationChange}
                    total={filteredClubs.length}
                    className="clubs-pagination"
                    hideOnSinglePage={true}
                />
            </div>

        </div>
    )
}

export default ClubsList;