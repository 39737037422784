import {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {AppContext} from "./context/context";
import InfoModal from "./InfoModal";

const Activation = () => {
    const {message, setMessage} = useContext(AppContext);

    const {logged} = useContext(AppContext);
    const navigate = useNavigate();
    useEffect(() => {
      setMessage(logged ? 'Twoje konto zostało aktywowane!' : 'Błąd aktywacji konta!');
    }, [logged])

    const handleOk = () => {
        setMessage('');
        if (logged) {
            navigate('/edycja-kol');
        } else {
            navigate('/rejestracja');
        }
    };

    return (
        <div className="Activation">
            <InfoModal message={message} handleOk={handleOk}>
            </InfoModal>
        </div>
    );
}

export default Activation;