import {useState} from "react";
import {Pagination} from "antd";

const mockNews = [
    {
        title: 'Zaproszenie do udziału w seminarium Kół Naukowych Studentów Wydziału Inżynierii Mechanicznej oraz Wydziału Mechatroniki, Uzbrojenia i Lotnictwa Wojskowej Akademii Technicznej.',
        date: '11-05-2023',
        desc:
            <div>
                <div>Szczegóły znajdują się pod linkiem: <a
                    href="https://kns.wim.wat.edu.pl/index.php/seminarium-kns" target="blank">KLIK</a></div>
                <div>Udział w seminarium należy zgłosić do 21.05.2023 za pomocą formularza: <a
                    href="https://forms.gle/2Xyt9FfLcVK83dvSA" target="blank">KLIK</a></div>
            </div>
    },
]

const mockAnnouncements = []

const Home = () => {
    const numEachPage = 5;
    const [minNews, setMinNews] = useState(0);
    const [maxNews, setMaxNews] = useState(numEachPage);
    const [minAnnouncements, setMinAnnouncements] = useState(0);
    const [maxAnnouncements, setMaxAnnouncements] = useState(numEachPage);
    const [news, setNews] = useState(mockNews);
    const [announcements, setAnnouncements] = useState(mockAnnouncements);

    const handleNewsChange = value => {
        setMinNews((value - 1) * numEachPage)
        setMaxNews(value * numEachPage)
    };
    const handleAnnouncementChange = value => {
        setMinAnnouncements((value - 1) * numEachPage)
        setMaxAnnouncements(value * numEachPage)
    };

    const getNews = (news) => {
        return news.slice(minNews, maxNews).map((n, index) => (
            <div key={index} className="news-item">
                <div>
                    <div className="section-title">{n.title}</div>
                    <div className="news-date">{n.desc}</div>
                    <div className="news-date">{n.date}</div>
                </div>
                <img src={n.image} className="news-img"/>
            </div>
        ))
    }
    const getAnnouncements = (announcements) => {
        return announcements.slice(minAnnouncements, maxAnnouncements).map((a, index) => (
            <div key={index} className="announcement-item">
                <div>{a.date}</div>
                <div className="section-title">{a.title}</div>
            </div>
        ))
    }

    return (
        <div className="Home page-container">
            <div className="page-wrapper">
                <div className="page-col">
                    <div className="page-title">Aktualności</div>
                    <div className="news-box">
                        {getNews(news)}
                    </div>
                    <Pagination
                        defaultCurrent={1}
                        defaultPageSize={numEachPage}
                        onChange={handleNewsChange}
                        total={news.length}
                        className="home-pagination"
                        hideOnSinglePage={true}
                    />
                </div>
                <div className="page-col">
                    {announcements.length ?
                        (<>
                            <div className="page-title">Ogłoszenia</div>
                            <div className="announcement-box">
                                {getAnnouncements(announcements)}
                            </div>
                            <Pagination
                                defaultCurrent={1}
                                defaultPageSize={numEachPage}
                                onChange={handleAnnouncementChange}
                                total={news.length}
                                className="home-pagination"
                                hideOnSinglePage={true}
                            />
                        </>)
                        : ''}
                </div>
            </div>
        </div>
    );
}

export default Home;
