import {useContext, useEffect, useState} from "react";
import {AppContext} from "../context/context";
import {getPrizes} from "./utils";

const ClubDetails = () => {
    const {clubList} = useContext(AppContext);
    const [clubs, setClubs] = useState([]);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    const {contests} = useContext(AppContext);

    useEffect(() => {
        setClubs(clubList);
    }, [clubList]);
    
    const club = clubs.filter(c => c.clubId == id)[0];
    return (
        <div className="ClubDetails page-container">
            {club &&
                <>
                    <div className="page-wrapper" style={{marginTop: 40}}>
                        <div className="page-col">
                            <img className="club-logo" alt="" src={club.logo ? club.logo.url : ''}/>
                            <div className="page-title">{club.clubName}</div>
                            <div className="club-description">{club.clubDescription}</div>
                        </div>
                        <div className="page-col">
                            <div className="details-box">
                                <div className="prizes">
                                    {getPrizes(club, contests)}
                                </div>
                                <div className="details-box-item">
                                    <div className="section-title">Opiekun koła naukowego</div>
                                    {club.patrons && club.patrons.map((p, index) => (<p className="box-text" key={index}>{p.name + ' ' + p.surname}</p>))}
                                </div>
                                <div className="details-box-item">
                                    <div className="section-title">Strona internetowa</div>
                                    <a className="box-text" href={club.clubSite} target="blank">{club.clubSite}</a>
                                </div>
                                <div className="details-box-item">
                                    <div className="section-title">Adres email</div>
                                    <p className="box-text">{club.clubEmail}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default ClubDetails;