import {Button, Card, Modal, Table, Tabs} from 'antd';
import {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {AppContext} from "./context/context";
import {PaperClipOutlined} from "@ant-design/icons";
import ClubsList from "./Club/ClubsList";
import {assignClubsToContest, getClubsList} from "../api/api";
import InfoModal from "./InfoModal";
import Meta from "antd/es/card/Meta";

const columns = [
    {
        title: 'Ważne terminy',
        dataIndex: 'date',
    },
    {
        title: 'Objaśnienie',
        dataIndex: 'description',
    },
];

const Contest = () => {
    const location = useLocation();
    const {logged, setLogged} = useContext(AppContext);
    const {message, setMessage} = useContext(AppContext);
    const {clubList, setClubList} = useContext(AppContext);
    const {userClubIds} = useContext(AppContext);
    const [contest, setContest] = useState({});
    const [clubsToReport, setClubsToReport] = useState([]);
    const [modalOpened, setModalOpened] = useState(false);
    const {contests} = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const edition = urlParams.get('edycja');

        const contest = contests.filter(c => c.year === edition)[0]
        contest && setContest(contest);
    }, [location, contests]);

    const getContestDescription = () => {
        return (
            <>
                <div className="page-title">{contest.name} {contest.status === '-1' && '(Zakończony)'}</div>
                <div className="">{contest.description}</div>
                <div className="page-title">Ważne terminy</div>
                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={contest.importantTerms}
                    size="middle"
                    locale={{
                        emptyText: 'Brak wpisów',
                    }}
                />
                <div className="page-title">Dokumenty do pobrania</div>
                {contest.docs && contest.docs.map(c => (
                    <div key={c.key}>
                        <PaperClipOutlined style={{marginRight: 10}}/>
                        <a href={c.url} download="">{c.name}</a>
                    </div>
                ))}
            </>
        )
    };

    const getWinners = () => {
        return (
            <ClubsList
                mode={'winners'}
                winners={[...contest.winnerClubIds || [], ...contest.laureateClubIds || []]}
            />
        )
    }

    const tabs = [
        {
            key: '1',
            label: 'OPIS KONKURSU',
            children: getContestDescription(),
        },
        ...contest.status === '-1' || contest.status === '-2'
            ? [{
                key: '2',
                label: `ZWYCIĘZCY KONKURSU`,
                children: getWinners(),
            }]
            : [],
    ];

    const handleClubsToReportChange = (e, clubId) => {
        setClubsToReport(actualClubsToReport =>
            actualClubsToReport.includes(clubId)
                ? actualClubsToReport.filter(id => id !== clubId)
                : [...actualClubsToReport, clubId]);
    }

    const participate = (clubsToReport) => {

        assignClubsToContest({contestId: contest.id, clubIds: clubsToReport})
            .then(response => {
                setMessage(response.msg);
            }).catch((response) => {
            if (response.status === 440) {
                setLogged(false);
                navigate('/logowanie')
            }
            response.json()
                .then((json) => {
                    setMessage(json.msg);
                }).catch((e) => {
                setMessage('Wystąpił błąd');
            })
        })

        setModalOpened(false);
        setClubsToReport([]);
    }

    const participateContest = () => {
        if (userClubIds.length === 1) {
            participate(userClubIds);
        } else {
            getClubsList().then(response => {
                setClubList(response);
                setModalOpened(true);
            }).catch((response) => {
                setMessage('Wystąpił błąd');
            })
        }
    }

    return (
        <div className="Contest page-container">
            <InfoModal message={message} handleOk={() => setMessage('')}></InfoModal>
            {Object.keys(contest).length !== 0 &&
                <>
                    <Modal title="Informacja"
                           open={modalOpened}
                           closable={false}
                           centered={true}
                           width="fit-content"
                           footer={[
                               <Button key="back" onClick={() => {
                                   setModalOpened(false);
                                   setClubsToReport([]);
                               }}>
                                   Anuluj
                               </Button>,
                               ...clubsToReport.length
                                   ? [<Button key="submit" type="primary" onClick={() => participate(clubsToReport)}>
                                       {clubsToReport.length > 1 ? 'Zgłoś koła' : 'Zgłoś koło'}
                                   </Button>]
                                   : []
                           ]}>
                        <div style={{maxWidth: 930}}>
                            <div style={{marginBottom: 20}}>Proszę wybrać koła naukowe, które zostaną zgłoszone do
                                konkursu.
                            </div>
                            <div style={{
                                display: 'flex',
                                gap: 30,
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                marginBottom: 30
                            }}>
                                {
                                    clubList
                                        .filter(c => userClubIds.includes(c.clubId))
                                        .map(c => (
                                            <div key={c.clubId}
                                                 style={{cursor: c.contestId ? 'not-allowed' : 'pointer'}}>
                                                <Card
                                                    onClick={(e) => handleClubsToReportChange(e, c.clubId)}
                                                    className={`clubCard ${clubsToReport.includes(c.clubId) ? "active" : ""}`}
                                                    style={{width: 290, pointerEvents: c.contestId ? 'none' : ''}}
                                                    hoverable
                                                    cover={
                                                        <div className="img-wrapper">
                                                            <img alt="" src={c.logo ? c.logo.url : ''}/>
                                                        </div>
                                                    }
                                                >
                                                    <Meta
                                                        style={{minHeight: 80}}
                                                        title={<div className="section-title">{c.clubName}</div>}
                                                        description={<div
                                                            style={{minHeight: 22}}>{c.clubNameShort}</div>}
                                                    />
                                                </Card>
                                                <div
                                                    style={{color: 'rgba(0, 0, 0, 0.45)'}}>{c.contestId && 'Koło naukowe zgłoszone do konkursu'}</div>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                    </Modal>

                    <div className="page-wrapper">
                        <div style={{width: '80%', maxWidth: 940, marginRight: 40}}>
                            <Tabs defaultActiveKey="1" items={tabs}/>
                        </div>
                        <div style={{minWidth: 420}}>
                            <div className="details-box">
                                <div className="details-box-item">
                                    <div className="section-title">Czas trwania konkursu</div>
                                    <p className="box-text">{contest.dateFrom} - {contest.dateTo}</p>
                                </div>
                                <div className="details-box-item">
                                    <div className="section-title">Czas przesyłania zgłoszeń</div>
                                    <p className="box-text">{contest.docsDeadline}</p>
                                </div>
                                <div className="details-box-item">
                                    <div className="section-title">Regulamin konkursu</div>
                                    <a className="box-text"
                                       href={`/assets/statute/${contest.year}/regulamin-konkursu-SKNWZ-${contest.year}.pdf`}
                                       target="balnk">regulamin - edycja {contest.year}</a>
                                </div>
                                <div className="details-box-item">
                                    {(contest.status === '0' || contest.status === '1') &&
                                        <Button
                                            disabled={!(contest.status === '1' && logged)}
                                            type="primary"
                                            onClick={participateContest}
                                        >
                                            BIORĘ UDZIAŁ
                                        </Button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
};

export default Contest;